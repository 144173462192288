/* You can add global styles to this file, and also import other style files */
@import "spinner";
@import "utils";
@import "rtl";
@import "responsive";

// Font Awesome
@import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "@fortawesome/fontawesome-free/scss/solid.scss";
@import "@fortawesome/fontawesome-free/scss/regular.scss";
@import "@fortawesome/fontawesome-free/scss/brands.scss";

// MDB SCSS
@import "mdb-angular-ui-kit/assets/scss/mdb.scss";

// Toastr SCSS
@import 'ngx-toastr/toastr';

@media print {
    .breadcrumb {
        display: none !important;
    }
}
